import React from 'react';
import ServiceCard from '../components/service-card';

const opdServices = [
    { title: "Chest Medicine" },
    { title: "General Medicine" },
    { title: "Obstetrics & Gynaecology" },
    { title: "Endocrinology" },
    { title: "E. N. T" },
    { title: "General, Laparoscopy and Oral Surgery" },
    { title: "Gastroenterology" },
    { title: "Neurology" },
    { title: "Cardiology" },
    { title: "Nephrology" },
    { title: "Rheumatology" },
    { title: "Psychiatry" },
    { title: "Neuro Rehab, Physiotherapy & Osteopathy Clinic" },
    { title: "Oncology" },
    { title: "Urology" },
    { title: "Haematology" }
];

const sortedOpdServices = opdServices.sort((a, b) => a.title.localeCompare(b.title));

const MultispecialityPolyClinic = () => {
    return (
        <div style={{ marginTop: 70 }}>
            <div className="container mx-auto" style={{ padding: '30px 20px' }}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {sortedOpdServices.map((service, index) => (
                        <ServiceCard key={index} title={service.title} type="opd" />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MultispecialityPolyClinic;
