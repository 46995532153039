import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Close, Menu } from '../assets/icons';
import Logo from "../assets/images/logo.jpeg"
import './header.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAnimation, setIsOpenAnimation] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
        setTimeout(() => {
            setIsOpenAnimation(!isOpenAnimation)
        }, 300)
    };
    return (
        <header className="bg-blue-500 text-white p-4 fixed top-0 left-0 right-0 z-50">
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/" className="text-2xl font-bold flex brand cursor-pointer">
                    <img src={Logo} alt="IMAGEON LOGO" className='h-6 w-6 mt-1 mr-1' />
                    IMAGE ON
                </Link>
                <div className="md:hidden relative z-30">
                    <button onClick={toggleMenu} className="focus:outline-none">
                        {isOpen ? (
                            <Close className="h-6 w-6" />
                        ) : (
                            <Menu className="h-6 w-6" />
                        )}
                    </button>
                </div>
                <nav className={`hidden md:flex md:items-center`}>
                    <Link to="/" className="block md:inline-block mr-4 mt-2 md:mt-0">Home</Link>
                    <Link to="/about" className="block md:inline-block mr-4 mt-2 md:mt-0">About</Link>
                    <Link to="/contact" className="block md:inline-block mt-2 md:mt-0">Contact</Link>
                </nav>
            </div>
            {isOpen && <div className={`md:hidden fixed inset-0 z-20 flex items-center justify-center ${isOpenAnimation ? 'menu-open' : 'menu-close'}`}>
                <nav className={`text-center space-y-4 mt-8 absolute z-30  ${isOpenAnimation ? '' : 'hidden'}`}>
                    <Link to="/" className="text-lg block text-white" onClick={toggleMenu}>Home</Link>
                    <Link to="/about" className="text-lg block text-white" onClick={toggleMenu}>About</Link>
                    <Link to="/contact" className="text-lg block text-white" onClick={toggleMenu}>Contact</Link>
                </nav>
                <div className="menu-background absolute bg-blue-500 transform transition-transform duration-500 ease-in-out z-20"></div>
            </div>}
        </header>
    );
};

export default Header;