import React from 'react';
import './hero.css';

const Hero = () => {
    return (
        <section className="hero-bg relative bg-gray-100">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="container mx-auto text-center relative z-10">
                <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">Welcome to <span className='brand'>IMAGE ON</span></h2>
                <p className="text-lg text-center mb-2">Artificial Intelligence (A.I.) Enabled Center</p>
                <p className="text-lg text-center mb-8">Polyclinic | Diagnostic | Neuro - Rehab</p>
            </div>
        </section>
    );
};

export default Hero;