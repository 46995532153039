import React from 'react';
import Hero from '../components/hero';
import ServiceCard from '../components/service-card';

const services = [
    { title: "Ultrasonography (4D)" },
    { title: "Peripheral Doppler Study" },
    { title: "Echocardiography" },
    { title: "Echocardiography Colour Doppler Study" },
    { title: "Pulmunary Function Test (PFT / Spirometry)" },
    { title: "BERA" },
    { title: "ECG" },
    { title: "EMG / NCV" },
    { title: "Holter Monitoring" },
    { title: "FNAC & FNAC (USG Guided)" },
    { title: "Neuro - Rehab" },
    { title: "Pathology" },
    { title: "Multispeciality Poly Clinic", to: "/multispeciality-poly-clinic" }
];

const Home = () => {
    return (
        <div>
            <Hero />
            <div className="container mx-auto" style={{ padding: '30px 20px' }}>
                <h1 className="text-3xl font-bold text-center mb-8">OUR SERVICES</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {services.map((service, index) => (
                        <ServiceCard key={index} title={service.title} to={service.to} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Home;