import React from 'react';

const About = () => {
    return (
        <div className="container mx-auto" style={{ padding: '80px 20px' }}>
            <h2 className="text-3xl font-bold mb-4">About Us</h2>
            <p>
                Welcome to <span className='font-semibold brand'>IMAGE ON</span>, 
                a premier diagnostic center in Kalyani and the first in the area to feature an A.I. enabled diagnostic system. 
                Our state-of-the-art facility is dedicated to providing the highest quality medical imaging and diagnostic services, 
                ensuring our patients receive the best care possible.
            </p>
            <h3 className='text-xl font-semibold mt-4'>Our Commitment</h3>
            <p className='mt-2'>
                Our center is dedicated to delivering accurate and timely diagnostic results. 
                We leverage cutting-edge A.I. technology to enhance the precision of our imaging and diagnostic processes, 
                setting a new standard in healthcare at Kalyani.
            </p>
            <h3 className='text-xl font-semibold mt-4'>Why Choose <span className='brand'>IMAGE ON</span>?</h3>
            <p className='mt-2'>
                <ul>
                    <li>
                        <span className='font-semibold'>Cutting-Edge Technology:</span> We are the first in Kalyani to incorporate A.I. into our diagnostic processes, ensuring the highest accuracy and efficiency.
                    </li>
                    <li>
                        <span className='font-semibold'>Comprehensive Care:</span> From advanced imaging to specialized outpatient services, we offer a full spectrum of medical care under one roof.
                    </li>
                    <li>
                        <span className="font-semibold">Community-Focused:</span> Understanding the importance of accessible healthcare, we provide special discounts for senior citizens and the specially abled. Our goal is to ensure that everyone in our community has access to top-tier medical services, embodying our commitment...<span className="font-semibold">HEALTHCARE FOR ALL</span>.
                    </li>
                </ul>
            </p>
            <p className='mt-4'>
                At <span className='font-semibold brand'>IMAGE ON</span>, your health is our priority. 
                We strive to provide a supportive and compassionate environment where you receive the highest quality medical care. 
                Visit us today and experience the world of A.I. in diagnostics.
            </p>
        </div>
    );
};

export default About;