import React from 'react';
import { Mail, MapPin, Phone, Whatsapp } from '../assets/icons';

const Contact = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold text-center mb-8" style={{ marginTop: 70 }}>Contact Us</h1>
            <div className='text-center'>
                <p className="text-lg m-auto mb-4 flex justify-center"><MapPin className="h-6 w-6 mr-1" /> A-1/45, Kalyani, Nadia</p>
                <p className="text-lg m-auto mb-4 flex justify-center"><Whatsapp className="h-6 w-6 mr-1" /> <span onClick={() => {
                        window.open(`https://api.whatsapp.com/send/?phone=+917439393208&text=I%20want%20to%20schedule%20an%20appointment&type=phone_number&app_absent=0`, '_blank')
                    }} className='cursor-pointer mr-1'>7439393208</span>
                </p>
                <p className="text-lg m-auto mb-4 flex justify-center"><Phone className="h-6 w-6 mr-1" /> 7439393208 / 9903172891</p>
                <p className="text-lg m-auto mb-8 flex justify-center"><Mail className="h-6 w-6 mr-1" /> m.imageon2023@gmail.com</p>
            </div>
        </div>
    );
};

export default Contact;