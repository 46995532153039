import React from 'react';
import { ServiceLine, Stethoscope } from '../assets/icons';
import { Link } from 'react-router-dom';

const ServiceCard = ({ title, type, to }) => {

    if(to) return <Link to={to} className={`bg-white shadow-lg p-6 rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl border border-gray-200 cursor-pointer`}>
        <div className="flex items-center">
            <div>
                {type === "opd" ? 
                    <Stethoscope className="text-blue-600 mr-3 h-8 w-8" />
                    :
                    <ServiceLine className="text-blue-600 mr-3 h-8 w-8" />
                }
            </div>
            <h2 className={`text-2xl font-semibold underline-blue underline text-gray-800 hover:text-blue-800 transition duration-300 ease-in-out`}>{title}</h2>
        </div>
        {/* Additional details or description can be added here */}
    </Link>

    return (
        <div className={`bg-white shadow-lg p-6 rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl border border-gray-200`} >
            <div className="flex items-center">
                <div>
                    {type === "opd" ? 
                        <Stethoscope className="text-blue-600 mr-3 h-8 w-8" />
                        :
                        <ServiceLine className="text-blue-600 mr-3 h-8 w-8" />
                    }
                </div>
                <h2 className={`text-2xl font-semibold text-gray-800`}>{title}</h2>
            </div>
            {/* Additional details or description can be added here */}
        </div>
    );
}

export default ServiceCard;
